<template>
  <v-container fluid>

    <editor
      :initial="dataItem"
      :seriesData="seriesArray"
      :defaultSeries="defaultSeries"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :objectName="objectName"
      :originData="originArray"
      @data="save"
    ></editor>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_componets/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      docStatus: true,
      docCreate: true,
      docCopyTo: true,
      seriesArray: [],
      originArray: [],
      defaultSeries: {},
      closePageConfirmationDialog: false,
      objectName: "Service Call",
      dataItem: {},
    };
  },
  methods: {
    save(data) {
      const url = "/service-calls";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            this.$router.push(`/service-call`);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },
    formSettings() {
      this.activeObject = 191;
      const objectId = 191;
      const self = this;

      this.$store
        .dispatch("get", `/form_settings/${objectId}`)
        .then((res) => {
          self.setup = res;
          self.defaultSeries = res.DfltSeries;
          self.seriesArray = res.Series;
          self.usersArray = res.users;
          self.originArray = res.serviceCallOrigins;
          self.departmentsArray = res.departments;
          self.BPLId = res.BPLId;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
  },
  created() {
    this.formSettings();
  },
};
</script>

<style lang="scss" scoped>
</style>